import { Dialog } from "vant";

export default {
  name: "TaskDetail",
  props: {
    id: String,
  },
  data() {
    return {
      taskData: {},
      active: 0,
      logList: [],
    };
  },
  mounted() {
    document.title = "任务详情";
    this.getTask();
  },
  methods: {
    getTask() {
      this.$axios({
        method: "get",
        url: `/api/mobile/task/detail/${this.id}`,
      }).then((res) => {
        this.taskData = res.data.data;
        console.log("this.taskData", this.taskData);

        this.getTaskLog();
      });
    },
    getTaskLog() {
      this.$axios({
        method: "get",
        url: `/api/mobile/taskLog/list/${this.id}`,
      }).then((res) => {
        let arr = [];
        for (let i = 0; i < res.data.data.length; i++) {
          if (
            res.data.data[i].status !== 22 &&
            res.data.data[i].status !== 23
          ) {
            arr.push(res.data.data[i]);
          }
        }
        this.logList = arr;
        console.log("this.logList", this.logList);
      });
    },
    gotoSubmit() {
      this.$axios({
        method: "post",
        url: "/api/mobile/task/accomplishTask",
        data: {
          uuid: this.taskData.uuid,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          const gotoUrl = res.data.data;
          window.location.href = gotoUrl;
        } else {
          Dialog.alert({
            message: res.data.message,
            theme: "round-button",
            confirmButtonColor: "#1a89fa",
          }).then(() => {
            // on close
          });
        }
      });
      // Dialog.alert({
      //   message: "请联系管理员重新分配任务",
      //   theme: "round-button",
      //   confirmButtonColor: "#1a89fa",
      // }).then(() => {
      //   // on close
      // });
    },
    gotoTask() {
      this.$axios({
        method: "post",
        url: "/api/mobile/task/accomplishTask",
        data: {
          uuid: this.taskData.uuid,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          const gotoUrl = res.data.data;
          window.location.href = gotoUrl;
        } else {
          Dialog.alert({
            message: res.data.message,
            theme: "round-button",
            confirmButtonColor: "#1a89fa",
          }).then(() => {
            // on close
          });
        }
      });
    },
    gotoAccept() {
      this.$axios
        .get(`/api/mobile/task/acceptTask/${this.taskData.uuid}`)
        .then((res) => {
          if (res.data.code === 200) {
            const gotoUrl = res.data.data;
            window.location.href = gotoUrl;
          } else {
            Dialog.alert({
              message: res.data.message,
              theme: "round-button",
              confirmButtonColor: "#1a89fa",
            }).then(() => {
              // on close
            });
          }
        });
    },
  },
};
